<script lang="jsx">
import { defineComponent } from 'vue'
import { containerRatio } from './utils'
import TransformImg from '../transformImg/transformImgProduct.vue'

export default defineComponent({
  name: 'CropImageContainer',

  components: {
    TransformImg
  },
  props: {
    /**
     * @param {Boolean} isSupportCropImage SSR渲染 是否支持补图 用于Apollo控制放量
     * @param {String} imgSrc 图片原始地址
     * @param {Object} transformImgProps 透传 transformImg 组件props
     * @param {String} containerBackground 容器背景颜色
     * @param {String} fixedRatio 容器固定宽高比 （宽-高）例如 fixedRatio="3-4"
     * @param {String} ignoreImgExt 忽略根据图片地址后缀决定容器比例的逻辑
     * */
    isSupportCropImage: {
      type: Boolean,
      default: false
    },
    imgSrc: {
      required: true,
      type: String,
      default: ''
    },
    containerBackground: {
      type: String,
      default: '#FFF'
    },
    transformImgProps: {
      type: Object,
      default: () => {
        return {}
      }
    },
    fixedRatio: {
      type: String,
      default: ''
    },
    ignoreImgExt: {
      type: Boolean,
      default: false
    },
    ignoreMask: {
      type: Boolean,
      default: false
    },
    imgThumb: {
      type: String,
      default: ''
    },
    notFsp: {
      type: Boolean,
      default: false
    }
  },
  render({ $props: props, $data: data, $attrs: listeners, $slots: slots }) {
    const { imgSrc, containerBackground, fixedRatio, ignoreImgExt, ignoreMask, transformImgProps, imgThumb, notFsp } = props

    const isHitApollo = () => {
      // Apollo 已经推全，不再需要传递 isSupportCropImage
      return true
      // if (typeof window === 'undefined') {
      //   return isSupportCropImage
      // }

      // const { RESOURCE_SDK } = gbCommonInfo
      // if  (RESOURCE_SDK && RESOURCE_SDK.isSupportCropImage) {
      //   return true
      // }

      // return false
    }


    const isSquareSrc = () => {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!isHitApollo()) {
        return false
      }
      
      return imgSrc.includes('_square')
    }

    const isSqufixSrc = () => {
      // 未命中 Apollo 依旧使用未被去后缀的图片地址
      if (!isHitApollo()) {
        return false
      }
      
      return imgSrc.includes('_squfix')
    }

    const isNotExtSrc = () => {
      return !isSqufixSrc() && !isSquareSrc()
    }


    const cropContainerStyle = () => {
      const backdropContainerStyle = {
        backgroundColor: `${containerBackground}`,
      }

      const containerRatioStyle = {
        paddingBottom: `calc(${containerRatio(fixedRatio, imgSrc).toFixed(2)} * 100%)`,
      }

      const containerStyle = {
        ...containerRatioStyle,
        ...backdropContainerStyle
      }

      if (ignoreImgExt) {
        return containerStyle
      }

      if (isNotExtSrc()) {
        return containerRatioStyle
      }

      return containerStyle
    }

    // 未命中 Apollo 依旧使用未被去后缀的图片地址
    const originImgSrc = !isHitApollo() ? imgSrc : imgSrc.replace(/(_square)|(_squfix)/ig, '')

    let imgClassName = 'crop-image-container__img'

    if (typeof transformImgProps.imgClassName === 'string') {
      imgClassName = `${transformImgProps.imgClassName} crop-image-container__img`
    }

    if (Array.isArray(transformImgProps.imgClassName)) {
      imgClassName = [
        ...transformImgProps.imgClassName,
        'crop-image-container__img'
      ]
    }

    const forwardProps = {
      ...transformImgProps,
      imgClassName
    }

    // 没传给默认值 LAZY_IMG_SOLID_COLOR
    if (forwardProps.loadImg === undefined) {
      forwardProps.loadImg = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdj+PTp038ACYYD1sVKshAAAAAASUVORK5CYII='
    }

    const handleClickImage = () => {
      listeners?.['onClickImage']?.()
    }

    const handleImgLoadSucc = (e) => {
      listeners?.['onImgLoadSucc']?.(e)
    }
    const handleImgLoadFail = (e) => {
      listeners?.['onImgLoadFail']?.(e)
    }

    let maskEle = null

    if (!ignoreMask) {
      maskEle = (
        <div class="crop-image-container__mask"></div>
      )
    }

    const handleTransformImgEl = ({ notFsp } = {}) => {
      return (
        (<TransformImg
        {...forwardProps}
        class={ notFsp ? 'not-fsp-element' : '' }
        imgSrc={originImgSrc}
        onImgLoadSucc={handleImgLoadSucc}
        onImgLoadFail={handleImgLoadFail}
      >
        {
          maskEle
        }
      </TransformImg>)
      )
    }
    return (
      <div
        class={[
          'crop-image-container',
          data.class,
          data.staticClass,
          notFsp ? 'not-fsp-element' : ''
        ]}
        style={cropContainerStyle()}
        data-before-crop-src={imgSrc}
        data-crop-rate={(containerRatio(fixedRatio, imgSrc))}
        onClick={handleClickImage}
      >
        {imgThumb ? (
          <div
            class="crop-image-container__thumb fsp-element"
            style={{ 'background-image': `url(${imgThumb})` }}
          >
            {handleTransformImgEl({ notFsp: true })}
          </div>
        ) : (
          handleTransformImgEl()
        )}
        {
          slots.content ? slots.content() : null
        }
      </div>
    )
  },
})
</script>

<style lang="less">
.crop-image-container {
  position: relative;
  overflow: hidden;
  height: 0;

  /* postcss-shein:ignore */
  &__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    object-fit: var(--crop-img-fit, contain);
    object-position: var(--crop-object-position, center);
  }

  &__thumb {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: center !important; /* stylelint-disable-line declaration-no-important */
    background-size: contain !important; /* stylelint-disable-line declaration-no-important */
    background-repeat: no-repeat !important; /* stylelint-disable-line declaration-no-important */
  }

  .addbag-normal-dialog {
    .sui-dialog__normal {
      width: 5.87rem;
      min-height: 3.33rem;
      /* stylelint-disable-next-line */
      .sui-dialog__body {
        padding: .6rem .4rem .47rem;
        text-align: center;
        position: relative;
        font-size: 12px;
        line-height: unset;
        /* stylelint-disable-next-line */
        .iconfont {
          text-align: center;
          margin: -.4rem auto .2rem;
          display: block;
          font-size: 45px;
        }
      }
      /* stylelint-disable-next-line */
      .sui-dialog__footer {
        padding: 0;
      }
    }
  }

  &__mask {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.03);
  }
}
</style>
